.modal-thumbnail {
  background-color: rgba(0, 0, 0, 0);
}

.modal-background {
  position: fixed;
  z-index: 10;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
}

.modal-content {
  margin: auto;
  display: block;
  width: 1000px;
  max-width: 90vw;
}

.caption {
  margin: auto;
  display: block;
  width: 1000px;
  max-width: 90vw;
  text-align: center;
  color: white;
  padding: 10px 0;
}

/* Zoom Animation */
.modal-content,
.caption {
  animation-name: zoom;
  animation-duration: 0.6s;
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: white;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

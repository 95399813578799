@import url("https://fonts.googleapis.com/css?family=Tangerine");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

:root {
  --animation-time: 2500ms;
  --animation-time-short: 1500ms;
  --negative-animation-distance: -40px;
  --positive-animation-distance: 40px;
  --primary-colour: #472e5e;
  --secondary-colour: #11193f;
  --tertiary-colour: #1b275a;
  --quaternary-colour: #9ba0ca;
  --background-colour: #1e2c55;
  --shadow-colour: #222222;
  --firefly-colour: yellow;
}

html,
body {
  height: 100%;
}

body {
  background: url(assets/background.jpg);
  background-color: var(--background-colour);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  margin: 0;
  line-height: 1.5;
  font-size: 18px;
  font-family: Georgia, "Times New Roman", Times, serif;
  height: 100vh;
  font-weight: normal;
  text-decoration: none;
  color: white;
  overflow-x: hidden;
  -ms-overflow-style: none; /* Remove scrollbar in IE and Edge */
  scrollbar-width: none; /* Remove scrollbar in Firefox */
}

body::-webkit-scrollbar {
  display: none; /* Remove scrollbar */
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1 { font-size: 2.5rem;}
h2 { font-size: 2rem; }
h3 { font-size: 1.75rem; }
h4 { font-size: 1.5rem; }
h5 { font-size: 1.25rem; }
h6 { font-size: 1rem; }

p {
  margin-top: 0;
  overflow: auto;
}

a {
  color: white;
  text-decoration: none;
  transition-duration: 0.5s;
}
a:hover {
  color: rgb(187, 187, 187);
}

button {
  cursor: pointer;
  transition: 0.3s;
  padding: 0;
  border: 0;
}
button:hover {
  opacity: 0.7;
}

.home-containter {
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: "Tangerine", Georgia, "Times New Roman", Times, serif;
  font-size: 32px;
  font-size: max(32px, 8vh);
  text-align: center;
}

.home-containter h1 {
  font-size: 48px;
  font-size: max(48px, 15vh);
}

.shadow {
  box-shadow: 10px 10px 20px var(--shadow-colour);
}

.profile-pic {
  border-radius: 50%;
  box-shadow: 10px 10px 20px var(--shadow-colour);
  width: 50vw;
  max-width: 200px;
  margin-right: 30px;
  margin-bottom: 20px;
}

.float-left {
  float: left;
}

.center-2 {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.content-container {
  padding-top: 90px;
  padding-bottom: 90px;
  width: 1000px;
  max-width: 90vw;
  margin-left: auto;
  margin-right: auto;
}

.container-no-nav {
  padding-top: 24px;
  padding-bottom: 24px;
}

.experience-container {
  padding-top: 150px;
}

.technologies {
  word-spacing: 10px;
  font-style: italic;
  text-align: center;
}

/* Stuff for experience page */
.row {
  display: flex;
  flex-wrap: wrap;
}

.experience-img {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  background-color: white;
  object-fit: cover;
}

/* Create two equal columns that sit next to each other */
.columns2 {
  flex: 45%;
  max-width: 49%;
  margin: 0 0.5%;
  margin-top: 8px;
  position: relative;
}

.columns1 {
  flex: 100%;
  max-width: 100%;
  position: relative;
  margin-bottom: 24px;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: xxx-large;
}

.layer {
  margin: 0;
  padding: 0;
  background-color: white;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 1s;
}

.layer:hover {
  opacity: 0.3;
}

/* ----------------------------- bullet points ----------------------------- */

.code-bullet-points {
  column-count: 2;
  list-style: none;
  padding-left: 0;
}
.code-bullet-points li {
  position: relative;
  padding-left: 33px;
}
.code-bullet-points li:before {
  content: "";
  width: 16px;
  height: 16px;
  position: absolute;
  background-image: url("./assets/code-bullet-point.png");
  background-size: cover;
  left: 4px;
  top: 5px;
}

.value-bullet-points {
  list-style: none;
  padding-left: 0;
}
.value-bullet-points li {
  position: relative;
  padding-left: 33px;
}
.value-bullet-points li:before {
  content: "";
  width: 22px;
  height: 22px;
  position: absolute;
  background-image: url("./assets/value-bullet-point.png");
  background-size: cover;
  left: 2px;
  top: 2px;
}

.animation-delay-1s {
    -webkit-animation-delay: 5s;
    -moz-animation-delay: 5s;
    -o-animation-delay: 5s;
    animation-delay: 5s;
  }
  
  .bounce { 
    animation: bounce 0.7s;
    animation-direction: alternate;
  }
           
  @-webkit-keyframes bounce {
      25%, 70%, 100% {transform: translateY(0);}
    0% {transform: translateY(-15px);}
      52% {transform: translateY(-8px);}
      88% {transform: translateY(-3px);}
  }
  
  @keyframes bounce {
      25%, 70%, 100% {transform: translateY(0);}
    0% {transform: translateY(-15px);}
      52% {transform: translateY(-8px);}
      88% {transform: translateY(-3px);}
  }
  
  .popout-link .popout {
    float: left;
    margin: 5px;
    margin-left: -26px;
    animation: pop-in ease 0.7s forwards;
  }
  @keyframes pop-in {
    100% {
      transform: translateX(20px) scale(0);
      opacity: 0;
    }
    30% {
      transform: translateX(-10px) scale(1.5);
    }
    0% {
      transform: translateX(0px) scale(1);
      opacity: 1;
    }
  }
  
  .popout-link:hover .popout {
    animation: pop-out ease 0.7s forwards;
  }
  @keyframes pop-out {
    0% {
      transform: translateX(20px) scale(0);
      opacity: 0;
    }
    30% {
      transform: translateX(-10px) scale(1.5);
    }
    100% {
      transform: translateX(0px) scale(1);
      opacity: 1;
    }
  }
  
  .fade-in-section {
    margin-top: 40px;
    opacity: 0;
    transform: translateX(100px);
    visibility: hidden;
    transition: opacity 0.6s ease-out, transform 2s ease-out;
    will-change: opacity, visibility;
  }
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
  
  .fade {
    opacity: 0;
    -webkit-animation-duration: var(--animation-time);
    -moz-animation-duration: var(--animation-time);
    -o-animation-duration: var(--animation-time);
    -ms-animation-duration: var(--animation-time);
    animation-duration: var(--animation-time);
  }
  
  .fade.down {
    animation: fadeDown ease var(--animation-time) forwards;
    -webkit-animation: fadeDown ease var(--animation-time) forwards;
    -moz-animation: fadeDown ease var(--animation-time) forwards;
    -o-animation: fadeDown ease var(--animation-time) forwards;
    -ms-animation: fadeDown ease var(--animation-time) forwards;
  }
  @keyframes fadeDown {
    from {
      transform: translateY(var(--negative-animation-distance));
      opacity: 0;
    }
    to {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  
  @-moz-keyframes fadeDown {
    from {
      transform: translateY(var(--negative-animation-distance));
      opacity: 0;
    }
    to {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  
  @-webkit-keyframes fadeDown {
    from {
      transform: translateY(var(--negative-animation-distance));
      opacity: 0;
    }
    to {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  
  @-o-keyframes fadeDown {
    from {
      transform: translateY(var(--negative-animation-distance));
      opacity: 0;
    }
    to {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  
  @-ms-keyframes fadeDown {
    from {
      transform: translateY(var(--negative-animation-distance));
      opacity: 0;
    }
    to {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  
  .fade.up {
    animation: fadeUp ease var(--animation-time) forwards;
    -webkit-animation: fadeUp ease var(--animation-time) forwards;
    -moz-animation: fadeUp ease var(--animation-time) forwards;
    -o-animation: fadeUp ease var(--animation-time) forwards;
    -ms-animation: fadeUp ease var(--animation-time) forwards;
  }
  @keyframes fadeUp {
    from {
      transform: translateY(var(--positive-animation-distance));
      opacity: 0;
    }
    to {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  
  @-moz-keyframes fadeUp {
    from {
      transform: translateY(var(--positive-animation-distance));
      opacity: 0;
    }
    to {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  
  @-webkit-keyframes fadeUp {
    from {
      transform: translateY(var(--positive-animation-distance));
      opacity: 0;
    }
    to {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  
  @-o-keyframes fadeUp {
    from {
      transform: translateY(var(--positive-animation-distance));
      opacity: 0;
    }
    to {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  
  @-ms-keyframes fadeUp {
    from {
      transform: translateY(var(--positive-animation-distance));
      opacity: 0;
    }
    to {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  
  .fade.left {
    animation: fadeLeft ease var(--animation-time) forwards;
    -webkit-animation: fadeLeft ease var(--animation-time) forwards;
    -moz-animation: fadeLeft ease var(--animation-time) forwards;
    -o-animation: fadeLeft ease var(--animation-time) forwards;
    -ms-animation: fadeLeft ease var(--animation-time) forwards;
  }
  @keyframes fadeLeft {
    from {
      transform: translateX(var(--positive-animation-distance));
      opacity: 0;
    }
    to {
      transform: translateX(0px);
      opacity: 1;
    }
  }
  
  @-moz-keyframes fadeLeft {
    from {
      transform: translateX(var(--positive-animation-distance));
      opacity: 0;
    }
    to {
      transform: translateX(0px);
      opacity: 1;
    }
  }
  
  @-webkit-keyframes fadeLeft {
    from {
      transform: translateX(var(--positive-animation-distance));
      opacity: 0;
    }
    to {
      transform: translateX(0px);
      opacity: 1;
    }
  }
  
  @-o-keyframes fadeLeft {
    from {
      transform: translateX(var(--positive-animation-distance));
      opacity: 0;
    }
    to {
      transform: translateX(0px);
      opacity: 1;
    }
  }
  
  @-ms-keyframes fadeLeft {
    from {
      transform: translateX(var(--positive-animation-distance));
      opacity: 0;
    }
    to {
      transform: translateX(0px);
      opacity: 1;
    }
  }
  
  .fade.right {
    animation: fadeRight ease var(--animation-time) forwards;
    -webkit-animation: fadeRight ease var(--animation-time) forwards;
    -moz-animation: fadeRight ease var(--animation-time) forwards;
    -o-animation: fadeRight ease var(--animation-time) forwards;
    -ms-animation: fadeRight ease var(--animation-time) forwards;
  }
  @keyframes fadeRight {
    from {
      transform: translateX(var(--negative-animation-distance));
      opacity: 0;
    }
    to {
      transform: translateX(0px);
      opacity: 1;
    }
  }
  
  @-moz-keyframes fadeRight {
    from {
      transform: translateX(var(--negative-animation-distance));
      opacity: 0;
    }
    to {
      transform: translateX(0px);
      opacity: 1;
    }
  }
  
  @-webkit-keyframes fadeRight {
    from {
      transform: translateX(var(--negative-animation-distance));
      opacity: 0;
    }
    to {
      transform: translateX(0px);
      opacity: 1;
    }
  }
  
  @-o-keyframes fadeRight {
    from {
      transform: translateX(var(--negative-animation-distance));
      opacity: 0;
    }
    to {
      transform: translateX(0px);
      opacity: 1;
    }
  }
  
  @-ms-keyframes fadeRight {
    from {
      transform: translateX(var(--negative-animation-distance));
      opacity: 0;
    }
    to {
      transform: translateX(0px);
      opacity: 1;
    }
  }
  
.image-gallery-image {
  width: 100%;
  margin-top: 8px;

  opacity: 0;
  visibility: hidden;
  transform: scale(0.3);
  transition: transform var(--animation-time-short) ease-out;
  will-change: visibility;
  will-change: opacity, visibility;
}
.image-gallery-image.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.image-gallery-animation {
  opacity: 0;
  animation: image-zoom ease var(--animation-time-short) forwards;
  -webkit-animation: image-zoom ease var(--animation-time-short) forwards;
  -moz-animation: image-zoom ease var(--animation-time-short) forwards;
  -o-animation: image-zoom ease var(--animation-time-short) forwards;
  -ms-animation: image-zoom ease var(--animation-time-short) forwards;
}

@keyframes image-zoom {
  from {
    transform: scale(0.3);
    opacity: 1;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

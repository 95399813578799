.slide-in {
  animation: slide-in ease 2s forwards;
}

@keyframes slide-in {
  100% {
    transform: translateY(0px);
  }

  0% {
    transform: translateY(-57px);
  }
}

.fa-github {
  font-size: 24px;
  top: 5px;
}

nav {
  position: fixed;
  width: 100vw;
  z-index: 10;
  background-color: var(--secondary-colour);
  overflow: hidden;
}

nav ul {
  margin: 0;
  border: 0;
  padding: 0;
  list-style-type: none;
}

nav ul li {
  float: left;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 20px;
  transition: 1s;
  height: 100%;
}

nav ul li:hover {
  background-color: var(--quaternary-colour);
  color: black;
}

nav ul li.active {
  background-color: var(--primary-colour);
  color: white;
}

.nav2 ul li.active {
  background-color: var(--tertiary-colour);
  color: white;
}

.nav2 ul li {
  font-size: 19px;
  padding: 10px 16px;
}

nav ul a {
  color: grey;
}

.navbar-container {
  width: 1000px;
  max-width: 90vw;
  margin-left: auto;
  margin-right: auto;
}

.nav2 {
  overflow-x: auto;
}

.nav2::-webkit-scrollbar {
  display: none;
}

.navbar-container-smallscreen {
  width: 100vw;
}

.navbar-rightside {
  float: right;
  cursor: pointer;
}

.dropdown-open {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.nav2 {
  z-index: 5;
  top: 57px;
  background-color: var(--primary-colour);
}

/* menu icon and animation */

.menu-icon {
  display: inline-block;
}

.bar1,
.bar2,
.bar3 {
  width: 30px;
  height: 4px;
  background-color: grey;
  margin: 4.8px 0;
  transition: 0.4s;
}

.bar1 {
  margin-top: 4px;
}

.bar3 {
  margin-bottom: 0;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-7px, 5.5px);
  transform: rotate(-45deg) translate(-7px, 5.5px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-6px, -6px);
  transform: rotate(45deg) translate(-6px, -6px);
}